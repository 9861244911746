
import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";
import LoaderForHrm from "../LoaderForHrm";
import styled from "styled-components";
import LoaderForMainSection from "../LoaderForMainSection";
const columnMapping = {
    // "1": "Draft",
    "1": "Defined/Draft/Ready",
    // "3": "Ready",
    "2": "In Progress",
    "3": "Review",
    "4": "Blocked",
    "5": "Prod/Demoed",
    // "8": "Prod",
    "6": "Closed"
};
const WindoForActualHours = styled.h4`
  top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999999; 
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function KanbanMainPage(props) {
    const [tasksByColumn, setTasksByColumn] = useState({
        // "1": { title: "Draft", tasks: [] },
        "1": { title: "Defined/Draft/Ready", tasks: [] },
        // "3": { title: "Ready", tasks: [] },
        "2": { title: "In Progress", tasks: [] },
        "3": { title: "Review", tasks: [] },
        "4": { title: "Blocked", tasks: [] },
        // "5": { title: "Demoed", tasks: [] },
        "5": { title: "Prod/Demoed", tasks: [] },
        "6": { title: "Closed", tasks: [] }
    });

    const [WindowForActualHour, setWindowForActualHour] = useState(false);
    const [actualHours, setActualHours] = useState('');
    const [taskLoading, setTaskLoading] = useState({});
    const [messageFromBackend, setMessageFromBackend] = useState('');
    const [messageType, setMessageType] = useState('');
    const [idStateClosed, setStateClosed] = useState('');
    const [destinationOfClosed, setDestinationOfClosed] = useState({});

    useEffect(() => {
        const records = props.newFilteredData || [];
        const columns = {
            "1": {
                title: "Defined/Draft/Ready",
                tasks: records.filter(task =>
                    ['Draft', 'Defined', 'Ready'].includes(task.Status__c)
                )
            },
            "2": { title: "In Progress", tasks: records.filter(task => task.Status__c === 'In Progress') },
            "3": { title: "Review", tasks: records.filter(task => task.Status__c === 'Review') },
            "4": { title: "Blocked", tasks: records.filter(task => task.Status__c === 'Blocked') },
            "5": {
                title: "Prod/Demoed",
                tasks: records.filter(task =>
                    ['Demoed', 'Prod'].includes(task.Status__c)
                )
            },
            "6": { title: "Closed", tasks: records.filter(task => task.Status__c === 'Closed') }
        };
        setTasksByColumn(columns);
    }, [props.newFilteredData, props.mainPageLoader]);

    function updateStatusById(id, newStatus) {
        const item = props.newFilteredData.find(entity => entity.Id === id);
        if (item) {
            item.Status__c = newStatus;
        } else {
         
        }
    }
    // const handleDragEnd = async (result) => {
    //     const { destination, source } = result;

    //     if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
    //         return;
    //     }
    //     const sourceColumnId = source.droppableId;
    //     const destColumnId = destination.droppableId;
    //     const sourceTasks = [...tasksByColumn[sourceColumnId].tasks];
    //     const destTasks = [...tasksByColumn[destColumnId].tasks];

    //     const [movedTask] = sourceTasks.splice(source.index, 1);
    //     destTasks.splice(destination.index, 0, movedTask);

    //     setTasksByColumn(prev => ({
    //         ...prev,
    //         [sourceColumnId]: { ...prev[sourceColumnId], tasks: sourceTasks },
    //         [destColumnId]: { ...prev[destColumnId], tasks: destTasks }
    //     }));

    //     if (destColumnId === "6") { 
    //         setWindowForActualHour(true);
    //         setStateClosed(movedTask.Id);
    //         setDestinationOfClosed({
    //             sourceDroppableId: sourceColumnId,
    //             sourceTasks,
    //             destinationDropId: destColumnId,
    //             destinationTask: destTasks,
    //         });
    //     } else {
    //         try {
             
    //             let statusVal=''
    //             if(destColumnId==1){
    //                 statusVal='Defined'
    //             }
    //             else if(destColumnId==5){
    //                 statusVal='Prod'
    //             }
    //             else{
    //                 statusVal=columnMapping[destColumnId]
    //             }
       
    //             setTaskLoading({ id: movedTask.Id, loading: true });
    //             const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${movedTask.Id}`, {
    //                 method: 'PUT',
    //                 headers: { 'Content-Type': 'application/json' },
    //                 body: JSON.stringify({
    //                     Status__c:statusVal ,
    //                     Actual_Hours__c: null,
    //                 }),
    //             });

    //             if (!response.ok) {
    //                 props.setMessageWithTimeout('Status not updated', 'error');
    //                 throw new Error('Failed to update project status');
    //             }
    //             updateStatusById(movedTask.Id,statusVal)
    //         } catch (error) {
                
    //             sourceTasks.splice(source.index, 0, movedTask);
    //             destTasks.splice(destination.index, 1);

    //             setTasksByColumn(prev => ({
    //                 ...prev,
    //                 [sourceColumnId]: { ...prev[sourceColumnId], tasks: sourceTasks },
    //                 [destColumnId]: { ...prev[destColumnId], tasks: destTasks }
    //             }));
    //             setTaskLoading({ id: movedTask.Id, loading: false });
    //             props.setMessageWithTimeout('Status not updated', 'error');
    //         } finally {
    //             setTaskLoading({ id: movedTask.Id, loading: false });
    //         }
    //     }
    // };
  
    const handleDragEnd = async (result) => {
        const { destination, source } = result;
        // console.log('result: ' + JSON.stringify(result))
    
        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return;
        }
    
        const sourceColumnId = source.droppableId;
        const destColumnId = destination.droppableId;
    
        // Clone the tasks from the source and destination columns to avoid mutating the state directly
        const sourceTasks = [...tasksByColumn[sourceColumnId].tasks];
        const destTasks = [...tasksByColumn[destColumnId].tasks];
    
        // Remove the task from the source and add it to the destination
        const [movedTask] = sourceTasks.splice(source.index, 1);
        destTasks.splice(destination.index, 0, movedTask);
    
        // Update the state with the new order
        setTasksByColumn(prev => ({
            ...prev,
            [sourceColumnId]: { ...prev[sourceColumnId], tasks: sourceTasks },
            [destColumnId]: { ...prev[destColumnId], tasks: destTasks }
        }));
    
        // If the destination column is '6', perform additional logic
        if (destColumnId === "6") {
            setWindowForActualHour(true);
            setStateClosed(movedTask.Id);
            setDestinationOfClosed({
                sourceDroppableId: sourceColumnId,
                sourceTasks,
                destinationDropId: destColumnId,
                destinationTask: destTasks,
            });
        } else {
            try {
                let statusVal = '';
                if (destColumnId == 1) {
                    statusVal = 'Defined';
                } else if (destColumnId == 5) {
                    statusVal = 'Prod';
                } else {
                    statusVal = columnMapping[destColumnId];
                }
    
                // Set loading state while waiting for the API request
                setTaskLoading({ id: movedTask.Id, loading: true });
    
                // Update the task status using the API
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${movedTask.Id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        Status__c: statusVal,
                        Actual_Hours__c: null,
                    }),
                });
    
                if (!response.ok) {
                    props.setMessageWithTimeout('Status not updated', 'error');
                    throw new Error('Failed to update project status');
                }
    
                // If status is updated successfully, update the task status in the state
                updateStatusById(movedTask.Id, statusVal);
            } catch (error) {
                // On error, rollback the state change to revert the drag
                sourceTasks.splice(source.index, 0, movedTask);
                destTasks.splice(destination.index, 1);
    
                setTasksByColumn(prev => ({
                    ...prev,
                    [sourceColumnId]: { ...prev[sourceColumnId], tasks: sourceTasks },
                    [destColumnId]: { ...prev[destColumnId], tasks: destTasks }
                }));
    
                // Set the loading state to false and show the error message
                setTaskLoading({ id: movedTask.Id, loading: false });
                props.setMessageWithTimeout('Status not updated', 'error');
            } finally {
                // Reset the loading state regardless of success or failure
                setTaskLoading({ id: movedTask.Id, loading: false });
            }
        }
    };
    
  
    const handleSave = async (e) => {
        e.preventDefault();

        try {
            setTaskLoading({ id: idStateClosed, loading: true });
            setWindowForActualHour(false);

            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/updateProjectEntityById/${idStateClosed}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Status__c: 'Closed',
                    Actual_Hours__c: Number(actualHours),
                }),
            });

            if (!response.ok) {
                setTaskLoading({ id: idStateClosed, loading: false });
                props.setMessageWithTimeout('Status not updated', 'error');
                throw new Error('Failed to update project status');
            }

            setTasksByColumn(prev => ({
                ...prev,
                [destinationOfClosed.destinationDropId]: { ...prev[destinationOfClosed.destinationDropId], tasks: destinationOfClosed.destinationTask }
            }));
            updateStatusById(idStateClosed,'Closed')
        } catch (error) {
            const sourceTasks = [...destinationOfClosed.sourceTasks];
            const movedTask = destinationOfClosed.destinationTask.find(task => task.Id === idStateClosed);

            sourceTasks.splice(destinationOfClosed.sourceTasks.length, 0, movedTask);

            setTasksByColumn(prev => ({
                ...prev,
                [destinationOfClosed.sourceDroppableId]: { ...prev[destinationOfClosed.sourceDroppableId], tasks: sourceTasks },
                [destinationOfClosed.destinationDropId]: { ...prev[destinationOfClosed.destinationDropId], tasks: destinationOfClosed.destinationTask.filter(task => task.Id !== idStateClosed) }
            }));
            setTaskLoading({ id: idStateClosed, loading: false });
            props.setMessageWithTimeout('Status not updated', 'error');
        } finally {
            setTaskLoading({ id: idStateClosed, loading: false });
            setActualHours('');
        }
    };


    const handlWindowForActualHour = () => {
        setWindowForActualHour(prev => !prev);

        if (WindowForActualHour) {
            const sourceTasks = [...destinationOfClosed.sourceTasks];
            const destTasks = [...destinationOfClosed.destinationTask];
            const movedTask = destTasks.find(task => task.Id === idStateClosed);
            const updatedDestTasks = destTasks.filter(task => task.Id !== idStateClosed);
            sourceTasks.splice(destinationOfClosed.sourceTasks.length, 0, movedTask);
            setTasksByColumn(prev => ({
                ...prev,
                [destinationOfClosed.sourceDroppableId]: { ...prev[destinationOfClosed.sourceDroppableId], tasks: sourceTasks },
                [destinationOfClosed.destinationDropId]: { ...prev[destinationOfClosed.destinationDropId], tasks: updatedDestTasks }
            }));
        }
        setTaskLoading({ id: idStateClosed, loading: false });
    };


    const handleTimeEstimatedHoursChange = (value) => {
        setActualHours(parseFloat(value));
    };
    return (
        <DragDropContext onDragEnd={handleDragEnd}>

            {WindowForActualHour && (
                <WindoForActualHours>
                    <form className="centered-window " onSubmit={handleSave}>
                        <div className='actualhourWindowCloseDiv' onClick={handlWindowForActualHour}>
                            <svg viewBox="0 0 24 24" fill="none" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                        </div>
                        <div className="formForWindoDiv">
                            <label htmlFor="" style={{ fontSize: "1.3rem" }}>Enter Actual Hours</label>
                            <input
                                type="number"
                                style={{ fontSize: "1.3rem" }}
                                min="0"
                                placeholder="Enter actual hours"
                                className="timeCardTimeinput"
                                value={actualHours}
                                onChange={(e) => handleTimeEstimatedHoursChange(e.target.value)}
                                required
                                step="any"
                                id="hrmmytimeInput"
                                taskLoading={taskLoading} />
                            <button type="submit" style={{ fontSize: "1.2rem" }}>Save</button>
                        </div>
                    </form>
                </WindoForActualHours>
            )}
            <div style={{ display: 'flex', position: 'relative',width: '100%', height:'calc(100vh - 112px - 1rem)' }}>
                <LoaderForMainSection loading={props.mainPageLoader} />
                {Object.keys(tasksByColumn).map(columnId => (
                    <Column
                        key={columnId}
                        title={tasksByColumn[columnId].title}
                        tasks={tasksByColumn[columnId].tasks}
                        id={columnId}
                        columnId={columnId}
                        handleIdClick={props.handleIdClick}
                        taskLoading={taskLoading}
                        count={tasksByColumn[columnId].tasks.length}
                    />
                ))}
            </div>
        </DragDropContext>
    );
}
