
import React from "react";
import styled, { css } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import './scroll.css';
import SmallLoader from "../SmallLoader";
const getBackgroundColor = (RecordType) => {
  switch (RecordType) {
    case "User Story":
      return "rgb(42, 192, 28)";
    case "Bug":
      return "red";
    case "Epic":
      return "#8f4fe2";
    case "Task":
      return "blue";
    default:
      return "rgb(16, 17, 162)";
  }
};

const getChipColor = (priority) => {
  switch (priority) {
    case "High":
      return "#ff0000";
    case "Medium":
      return "#ffbf00";
    case "Low":
      return "#00c853";
    default:
      return "red";
  }
};

const CardContainer = styled.div`
  border: 3px solid ${(props) => getBackgroundColor(props.RecordType)} !important;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
cursor:pointer !important;
  ${(props) =>
    props.isDragging &&
    css`
      transform: rotate(2deg);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    `}

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const CardTitle = styled.h4`
  font-size: 0.87rem;
  font-weight: 700;
  color: black;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CardInfoD = styled.div`
  color: #333;
  margin: 0.5rem 0;
`;

const ChipDiv = styled.div`
  border-radius: 0.4rem;
  font-weight: bold;
  background-color: ${(props) => getChipColor(props.priority)};
  width: fit-content;
  text-align: center;
  padding: 0.1rem 1rem;
  font-size: 0.6rem;
  color: white;
  display:flex;
  align-items: center;
`;

const CardInfo = styled.div`
  font-size: 0.8rem;
  color: #333;
  margin: 0.5rem 0 0.1rem 0;
`;

const AssignedTo = styled.div`
  margin-top: 10px;
  font-size: 0.7rem;
  color: black;
`;

const Card = ({ task, index, handleIdClick, taskLoading }) => {
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";
    const [year, month, day] = dateTimeString.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    const dayOfMonth = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[date.getMonth()];
    return `${monthName} ${dayOfMonth}`;
  };

  return (
    <Draggable draggableId={task.Id} index={index} key={task.Id}>
      {(provided, snapshot) => (
        <CardContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
          RecordType={task.RecordType?.Name || "Unknown"}
          onClick={() => handleIdClick(task.Id)}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            <ChipDiv priority={task.Priority__c || "Unknown"}>
              {task.Priority__c || "Unknown"}
            </ChipDiv>
            {taskLoading.id == task.Id && taskLoading.loading ? (<SmallLoader />):(<ChipDiv style={{backgroundColor:'#1011a2'}}>
              {task.Status__c || "N/A"}
            </ChipDiv>)}
            
          </div>
          <p className="projectNameCard">{task.Project__r?.Title__c || "No Project"}</p>
          <CardInfo>{task.Name || "No Name"}</CardInfo>
          <CardTitle>{task.Title__c || "No Title"}</CardTitle>
          <div className="dueDateDivKanbanCard">
            Estimates: <span className="detailHD">&nbsp;{task.Estimated_Hours__c || 0}</span>
          </div>
          <div className="dueDateDivKanbanCard">
            Due on: <span className="detailHD">&nbsp;{formatDateTime(task.Due_Date__c)}</span>
          </div>
          <AssignedTo>{task.Assigned_To__r?.Name || 'Unassigned'}</AssignedTo>
        </CardContainer>
      )}
    </Draggable>
  );
};

export default Card;
