import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import DateCalender from './DateCalender';
import LoaderForHrm from './LoaderForHrm';
import './css/timeCard.css';
import HrmNavbar from './HrmNavbar';
import { HashLink as Link } from "react-router-hash-link";
import DatePickerForLeaveForm from './DatePickerForLeaveForm';
/*
*********************************************************************************************************
*  @Parent Component           :    AdminPageOfHRM
*  @React Component Name       :    TimeCard
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Time card Page 
********************************************************************************************************
*/
export default function TimeCard() {
    const [timeCards, setTimeCards] = useState([1]);
    const [allProjectOption, setAllProjectOption] = useState([]);
    const [allTasksByProject, setAllTasksByProject] = useState({});
    const [timeCardValues, setTimeCardValues] = useState([]);
    const [filedTimeCardData, setFiledTimeCardData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buttonloading, setbuttonloading] = useState(false);
    const [messageFromBackend, setMessageFromBackend] = useState('');
    const [openIndex, setOpenIndex] = useState(null);
    const [showTimeEnteries, setShowTimeEnteries] = useState(false);
    const [showTimeEntries, setShowTimeEntries] = useState(false);
    const [time, setTime] = useState(new Date());
    const [editId, setEditId] = useState('')
    const [timeCardsShowData, setTimeCardsShowData] = useState([])
    const [messageType, setMessageType] = useState('');
    const [editMode, setEditMode] = useState(false)
    const [countApproved, setCountApproved] = useState(0);
    const [countSubmitted, setCountSubmitted] = useState(0);
    const [countRejected, setCountRejected] = useState(0);

    const [hoursApproved, setHoursApproved] = useState(0);
    const [hoursSubmitted, setHoursSubmitted] = useState(0);
    const [hoursRejected, setHoursRejected] = useState(0);

    const [countLogTime, setCountLogTime] = useState(0)
    const [trackAllEntity, setTrackAllEntity] = useState([])
    const [selectedOptionForSort, setSelectedOptionForSort] = useState('Date');
    const [sortedDataForTimeCard, setSortedDataForTimeCard] = useState([])
    const [deleteCardId, setDeleteCardId] = useState(null)




    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       setMessageWithTimeout
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       et the message on top of page and remove it after 3 second 
    *********************************************************************************************************
    */

    const setMessageWithTimeout = (message, typeOFmessage) => {
        setMessageFromBackend(message);
        setMessageType(typeOFmessage);
        setTimeout(() => {
            setMessageFromBackend('');
            setMessageType('');
        }, 3000);
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       fetchPosts
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       fetch all projects and and push all project with related there Task on the setAllTasksByProject and setAllProjectOption
    *********************************************************************************************************
    */
    const endDateFun = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            setTime(date);
        }
    };

    const fetchPosts = useCallback(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/fetchProjectDataForTimeCard/${localStorage.getItem('contactUserId')}`)
            .then((res) => res.json())
            .then((resData) => {
                const parsedResponseJSON = JSON.parse(resData.responseJSON);
                const tasksByProject = parsedResponseJSON.reduce((acc, item) => {
                    const projAlId = item.projAlId;
                    if (!acc[projAlId]) {
                        acc[projAlId] = [];
                    }
                    item.entities.forEach(entity => {
                        acc[projAlId].push({
                            label: entity.title,
                            value: entity.peId
                        });
                    });
                    return acc;
                }, {});

                const projAlIdArray = parsedResponseJSON.map(item => ({
                    label: item.title,
                    value: item.projAlId
                }));
                setAllProjectOption(projAlIdArray);
                setAllTasksByProject(tasksByProject);
                setLoading(false);
                // setMessageWithTimeout('Successfully fetch projects', 'success');
            })
            .catch((err) => {
                console.error('Error fetching posts:', err);
                setLoading(false);
                setMessageWithTimeout('Failed to fetch project data', 'error');
            });
    }, []);

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       fetchAllTimeCard
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       fetch all time card for the perticular user and set the state setTimeCardsShowData with that data 
    *********************************************************************************************************
    */


    const fetchAllTimeCard = useCallback(() => {

        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getAllTimeCardData/${localStorage.getItem('contactUserId')}`)
            .then((res) => res.json())
            .then((resData) => {
                let parsedTimeCardsShowData;
                if (typeof resData.responseJSON === 'string') {
                    try {
                        parsedTimeCardsShowData = JSON.parse(resData.responseJSON);
                    } catch (e) {
                        // console.error("Failed to parse timeCardsShowData:", e);
                        parsedTimeCardsShowData = [];
                    }
                } else {
                    parsedTimeCardsShowData = resData.responseJSON;

                }
                const groupedByDate = parsedTimeCardsShowData.reduce((acc, curr) => {
                    const dateIndex = acc.findIndex(item => item.timeCardDate === curr.timeCardDate);
                    if (dateIndex === -1) {
                        acc.push({
                            timeCardDate: curr.timeCardDate,
                            entries: [curr]
                        });
                    } else {
                        acc[dateIndex].entries.push(curr);
                    }
                    return acc;
                }, []);

                setSortedDataForTimeCard(groupedByDate);
                setLoading(false);
                SortedData(selectedOptionForSort, groupedByDate)
                getCountOfTimeCardStatus()
            })
            .catch((err) => {
                console.error('Error fetching blog posts:', err);
                setMessageWithTimeout('Failed to fetch data', 'error');
            });
    }, []);

    const updateTheTimeCardData = (data) => {

        const ArrayTimeCard = {
            entries: data,
            timeCardDate: data[0].timeCardDate
        };

        setTimeCardsShowData(prev => {
            if (!Array.isArray(prev)) {
                prev = [];
            }
            const existingIndex = prev.findIndex(item => item.timeCardDate === ArrayTimeCard.timeCardDate);
            if (existingIndex !== -1) {
                prev[existingIndex] = ArrayTimeCard;
            } else {
                prev = [...prev, ArrayTimeCard];
            }
            const sortedData = prev.sort((a, b) => {
                const dateA = new Date(a.timeCardDate.split('/').reverse().join('/'));
                const dateB = new Date(b.timeCardDate.split('/').reverse().join('/'));
                return dateB - dateA;
            });

            return sortedData;
        });
        setSortedDataForTimeCard(prev => {
            if (!Array.isArray(prev)) {
                prev = [];
            }
            const existingIndex = prev.findIndex(item => item.timeCardDate === ArrayTimeCard.timeCardDate);
            if (existingIndex !== -1) {
                prev[existingIndex] = ArrayTimeCard;
            } else {
                prev = [...prev, ArrayTimeCard];
            }
            const sortedData = prev.sort((a, b) => {
                const dateA = new Date(a.timeCardDate.split('/').reverse().join('/'));
                const dateB = new Date(b.timeCardDate.split('/').reverse().join('/'));
                return dateB - dateA;
            });

            return sortedData;
        });
        getCountOfTimeCardStatus()
    };


    const fetchTimeCardEntityId = useCallback(async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getTimeCardId/${localStorage.getItem('contactUserId')}/${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const resData = await response.json();
            return resData;
        } catch (err) {
            console.error('Error fetching time card data:', err);
            setMessageWithTimeout('Failed to fetch TimeCard Id', 'error');
            throw err;
        }
    });
    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       useEffect
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       initial call when user lend on the page 
    *********************************************************************************************************
    */

    useEffect(() => {
        fetchPosts();
        fetchAllTimeCard()
        getCountOfTimeCardStatus()
    }, []);


    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       handleProjectChange
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       it set project value(Id) when it select in the time card and its all previous task remaining same 
    *********************************************************************************************************
    */

    const handleProjectChange = (index, value) => {
        const newValues = [...timeCardValues];
        newValues[index] = { ...newValues[index], projAlId: value, taskId: null };
        setTimeCardValues(newValues);
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       handleTaskIdChange
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       This method select the perticulare project Task which was select or remaining values are same 
    *********************************************************************************************************
    */

    const handleTaskIdChange = (index, value) => {
        const newValues = [...timeCardValues];
        newValues[index] = { ...newValues[index], taskId: value };
        setTimeCardValues(newValues);
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       handleLogoutOfhrm
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       it set the decription/comment on the task 
    *********************************************************************************************************
    */

    const handleTextareaChange = (index, value) => {
        const newValues = [...timeCardValues];
        newValues[index] = { ...newValues[index], description: value };
        setTimeCardValues(newValues);
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       TimeCard
*   @Method                     :       handleTimeInputChange
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       it set the perticulare task working hours if user set out of range(0 to 8 ) then it show error or not submit the time card 
*********************************************************************************************************
*/
    const handleTimeInputChange = (index, value) => {
        const [hours] = value.split(':').map(Number);
        if (hours < 0 || hours > 8) {
            setMessageWithTimeout("Enter valid time (0 to 8 hours)", 'error');
        } else {
            const newValues = [...timeCardValues];
            newValues[index] = { ...newValues[index], hours: value };
            setTimeCardValues(newValues);
        }
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       addTimeCard
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       it add the new timecard when user click on the Add Entry and previous data will be same 
    *********************************************************************************************************
    */

    const addTimeCard = () => {

        setTimeCards([...timeCards, {}]);
        setTimeCardValues([...timeCardValues, { isDeleted: false }]);
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       removeTimeCard
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       Remove the timcard when user click on the delete 
    *********************************************************************************************************
    */
    // const removeTimeCard = (index, id, isDeleted) => {
    //     const newTimeCards = [...timeCards];
    //     const newTimeCardValues = [...timeCardValues];
    //     if (id) {
    //         newTimeCardValues[index] = { ...newTimeCardValues[index], isDeleted: true };
    //         setTimeCardValues(newTimeCardValues);
    //     } else {
    //         newTimeCards.splice(index, 1);
    //         newTimeCardValues.splice(index, 1);
    //      

    //             setTimeCards(newTimeCards);
    //             setTimeCardValues(newTimeCardValues);


    //     }
    // };

    const removeTimeCard = (index, id) => {
        // Check if the index is valid
        if (index < 0 || index >= timeCards.length) {
            // console.error('Invalid index:', index);
            return;
        }
        const newTimeCards = [...timeCards];
        const newTimeCardValues = [...timeCardValues];

        if (id) {
            newTimeCardValues[index] = { ...newTimeCardValues[index], isDeleted: true };
            const allDeleted = newTimeCardValues.every(item => item.isDeleted);
            if (allDeleted) {
                setTimeCards([...newTimeCardValues, {}]);
                setTimeCardValues([...newTimeCardValues, { isDeleted: false }]);
            }
            else {
                setTimeCardValues(newTimeCardValues);
            }
        }
        else {
            newTimeCards.splice(index, 1);
            newTimeCardValues.splice(index, 1);
            if (newTimeCards.length == 0) {

                newTimeCards.push({});
                newTimeCardValues.push({

                    "isDeleted": false,
                    "projAlId": {
                        "label": "",
                        "value": ""
                    },
                    "taskId": {
                        "label": "",
                        "value": ""
                    },
                    "description": "",
                    "hours": ""

                });
                setTimeCards(newTimeCards);
                setTimeCardValues(newTimeCardValues);
            }
            else {
                const allDeleted = newTimeCardValues.every(item => item.isDeleted);

                if (allDeleted) {

                    newTimeCardValues.push({

                        "isDeleted": false,
                        "projAlId": {
                            "label": "",
                            "value": ""
                        },
                        "taskId": {
                            "label": "",
                            "value": ""
                        },
                        "description": "",
                        "hours": ""

                    });
                    setTimeCards(newTimeCardValues);
                    setTimeCardValues(newTimeCardValues);
                }
                else {

                    setTimeCards(newTimeCards);
                    setTimeCardValues(newTimeCardValues);
                }

            }
        }
    };


    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       isDatePresent
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       It check user field the time card or not on date which user want 
    *********************************************************************************************************
    */

    const isDatePresent = (data, date) => {

        return data.some(entry => entry.timeCardDate === date);
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       resetForm
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       when user submit the timecard then mark the null all input field 
    *********************************************************************************************************
    */

    const resetForm = () => {
        setTimeCardValues([{}]);
        setTimeCards([{}]);
        // setTime('');
        setEditId('');
        setEditMode(false);
        setShowTimeEntries(false);
        getCountOfTimeCardStatus()
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       handleSaveTimeCard
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       it check the user alrady fil or not, all entry is field or not, working hours is in there range it all are currect the save the timecard in the SalesForce.
    *********************************************************************************************************
    */

    const handleSaveTimeCard = async () => {
        setbuttonloading(true)
        if (timeCardValues.length === 0) {
            setbuttonloading(false)
            setMessageWithTimeout("Please add at least one time card entry before updating.", 'error');
            return;
        }
        for (const timeCard of timeCardValues) {
            if (!timeCard.projAlId || !timeCard.taskId || !timeCard.description?.trim() || !timeCard.hours) {
                setbuttonloading(false)
                setMessageWithTimeout("Please fill out all fields before saving.", 'error');
                return;
            }
        }

        if (!time) {
            setbuttonloading(false)
            setMessageWithTimeout("Date is not defined or invalid.", 'error');

            return;

        }
        const empId = localStorage.getItem('contactUserId');
        const id = editMode ? editId : "";
        const formatDate = (date) => {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString();
            return `${day}/${month}/${year}`;
        };
        const formattedDate = formatDate(time);

        if (!editMode) {
            const dateExists = isDatePresent(sortedDataForTimeCard, formattedDate);

            if (dateExists) {
                setbuttonloading(false)
                setMessageWithTimeout("Time card for this date has already been filled.", 'error');
                return;
            }
        }

        const newTimeCardEntries = timeCardValues.map(timeCard => ({
            projAlId: timeCard.projAlId.value,
            hours: parseFloat(timeCard.hours),
            peId: timeCard.taskId.value,
            description: timeCard.description,
            isDeleted: false
        }));
        const newTimeCardEntry = {
            timeCardDate: formattedDate,
            empId: empId,
            Id: id,
            timeCardEntries: newTimeCardEntries
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/CreateTimeCard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newTimeCardEntry)
            });
            if (!response.ok) {
                setbuttonloading(false)
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            const timeCardIdData = await fetchTimeCardEntityId(result.timeCardId)

            updateTheTimeCardData(JSON.parse(timeCardIdData.responseJSON))
            if (editMode) {
                const updatedData = filedTimeCardData.map(data => data.Id === id ? newTimeCardEntry : data);
                setFiledTimeCardData(updatedData);
            } else {
                setFiledTimeCardData(prevData => [...prevData, newTimeCardEntry]);
            }

            resetForm();
            setbuttonloading(false)
            setShowTimeEnteries(false)
            fetchPosts();
            setTime('');
            getCountOfTimeCardStatus()

        } catch (error) {
            console.log("error", error)
            setbuttonloading(false)
            console.error('Error sending data to the API:', error);
            setMessageWithTimeout("Failed to save time card. Please try again.", 'error');
        }
    };
    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       getTaskOptions
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       it return the task Id realted to the there project  otherwise it show null 
    *********************************************************************************************************
    */

    const getTaskOptions = (projAlId) => {
        return allTasksByProject[projAlId] || [];
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       toggleDiv
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       it open the dropdown of the clicked timeDard 
    *********************************************************************************************************
    */

    const toggleDiv = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       formatDate
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       it return the formated time 
    *********************************************************************************************************
    */

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${year}-${month}-${day}`;
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       timeDate
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       check this date already fields or not to given date 
    *********************************************************************************************************
    */

    const timeDate = (date) => {
        const formattedDate = formatDate(date);

        setTime(date);

        const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        const startOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
        const endOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
        if (date) {
            const dateExists = isDatePresent(sortedDataForTimeCard, formattedDate);
            setTimeCardValues([]);
            const todayDate = formatDate(new Date());
            if (dateExists) {
                setTimeCards([1])
                setShowTimeEnteries(false)
                setMessageWithTimeout(`You are already filed the time card of this data ${formatDate(date)}`, "error")
            }
            // else if (date <= endOfLastMonth) {
            //     setTimeCards([1])
            //     setShowTimeEnteries(false)
            //     setMessageWithTimeout(`You are not allowed to fill the time card for the last month: ${formatDate(date)}`, "error");
            // }
            else if (formattedDate > todayDate) {
                setTimeCards([1])
                setShowTimeEnteries(false)
                setMessageWithTimeout(`The selected date cannot be greater than today's date ${formatDate(date)}`, "error")
            }

            else {
                setShowTimeEnteries(true);
                resetForm();
                setMessageWithTimeout('', '');
            }
        }
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       findValueByLabel
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       find the project value orrelated there task labe 
    *********************************************************************************************************
    */

    const findValueByLabel = (label) => {
        const option = allProjectOption.find(option => option.label === label);
        return option ? option.value : '';
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       findOfTaskIdValueByLabel
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       fiend the task id or label accoding to the pass the json
    *********************************************************************************************************
    */

    const findOfTaskIdValueByLabel = (json, labelToFind) => {
        for (const key in json) {
            const entries = json[key];
            const foundEntry = entries.find(entry => entry.label === labelToFind);
            if (foundEntry) {
                return foundEntry.value;
            }
        }
        return null;
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       TimeCard
    *   @Method                     :       handelEditBtn
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       It edit button handler it render the entry on the timecard entry 
    *********************************************************************************************************
    */
    const handelEditBtn = (index, dateValue, id) => {
        setEditMode(true)
        setShowTimeEnteries(true);

        setTime(new Date(dateValue))

        const selectedTimeCardData = timeCardsShowData[index];
        const updatedTimeCardValues = selectedTimeCardData.entries.reduce((accumulator, entry) => {
            setEditId(entry.Id)
            const updatedEntries = entry.timeCardEntries.map(timeCardEntry => {
                const projAlIdLabel = timeCardEntry.projAlId;
                const taskIdLabel = timeCardEntry.peId;
                const projectId = findValueByLabel(projAlIdLabel);
                const taskID = findOfTaskIdValueByLabel(allTasksByProject, timeCardEntry.peId)
                const Id = timeCardEntry.Id;
                addTimeCard();
                return {
                    projAlId: {
                        label: projAlIdLabel,
                        value: projectId
                    },
                    taskId: {
                        label: taskIdLabel,
                        value: taskID
                    },
                    description: timeCardEntry.description || '',
                    hours: timeCardEntry.hours || '',
                    Id: Id,
                    status: timeCardEntry.status || '',
                    isDeleted: timeCardEntry.isDeleted
                };
            });
            return [...accumulator, ...updatedEntries];
        }, []);
        setTimeCardValues(updatedTimeCardValues);
        setTrackAllEntity(updatedTimeCardValues);
        setTimeCards(updatedTimeCardValues);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       TimeCard
*   @Method                     :       handelUpdateCard
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       It submit btn for the save the updated time Card or updated time card
*********************************************************************************************************
*/

    const handelUpdateCard = async () => {
        setbuttonloading(true)
        if (timeCardValues.length === 0) {
            setLoading(false);
            setbuttonloading(false)
            setMessageWithTimeout("Please add at least one time card entry before updating.", 'error');
            return;
        }
        const allDeleted = timeCardValues.every(timeCard => timeCard.isDeleted);
        for (const timeCard of timeCardValues) {
            if (!timeCard.projAlId || !timeCard.taskId || !timeCard.description.trim() || !timeCard.hours || allDeleted) {
                setbuttonloading(false)
                setMessageWithTimeout("Please fill out all fields before saving.", 'error');
                return;
            }
        }
        const empId = localStorage.getItem('contactUserId');
        const Id = editId;
        const changedIndices = [];
        timeCardValues.forEach((item, index) => {
            const originalItem = trackAllEntity[index];
            if (JSON.stringify(item) !== JSON.stringify(originalItem)) {
                changedIndices.push(item);
            }
        });
        const newTimeCardEntries = changedIndices.map(timeCard => ({
            projAlId: timeCard.projAlId.value,
            hours: parseFloat(timeCard.hours),
            peId: timeCard.taskId.value,
            description: timeCard.description,
            Id: timeCard.Id,
            isDeleted: timeCard.isDeleted
        }));
        const newTimeCardEntry = {
            timeCardDate: time,
            empId: empId,
            Id: Id,
            timeCardEntries: newTimeCardEntries
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/CreateTimeCard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newTimeCardEntry)
            });
            if (!response.ok) {
                setbuttonloading(false)
                throw new Error('Network response was not ok');
            }
            const result = await response.json();

            const timeCardIdData = await fetchTimeCardEntityId(editId)
            updateTheTimeCardData(JSON.parse(timeCardIdData.responseJSON))
            setFiledTimeCardData(prevData => Array.isArray(prevData) ? [...prevData, newTimeCardEntry] : [newTimeCardEntry]);
            setTimeCardValues([]);
            setShowTimeEnteries(!showTimeEnteries);
            fetchPosts();
            resetForm()
            setEditMode(false)
            setbuttonloading(false)
            setTrackAllEntity([])
            setTime('');
            setMessageWithTimeout(result.errorMessage, 'error');
            getCountOfTimeCardStatus()
        } catch (error) {
            setbuttonloading(false)
            console.error('Error sending data to the API:', error);
            setMessageWithTimeout("Failed to save time card. Please try again.", 'error');
        }
    }
    const getCountOfTimeCardStatus = () => {
        let countFunApproved = 0;
        let countFunSubmitted = 0;
        let countFunRejected = 0;
        let countFunLogTime = 0;
        let formattedTotalHours = 0;
        let ApprovedHours = 0;
        let SubmittedHours = 0;
        let RejectedHours = 0;
        Array.isArray(timeCardsShowData) && timeCardsShowData.forEach((card) => {
            const statuses = card.entries.flatMap(entry =>
                entry.timeCardEntries.map(timeCardEntry => timeCardEntry.status)
            );
            card.entries.flatMap(entry =>
                entry.timeCardEntries.map(timeCardEntry => {
                    if (timeCardEntry.status === "Rejected") { RejectedHours += timeCardEntry.hours }
                    if (timeCardEntry.status === "Approved") { ApprovedHours += timeCardEntry.hours }
                    if (timeCardEntry.status === "Submitted") { SubmittedHours += timeCardEntry.hours }
                })
            );
            // timeLog.forEach(time => {
            //     countFunLogTime += time;
            // });
            const totalHours = RejectedHours + ApprovedHours + SubmittedHours;
            formattedTotalHours = totalHours.toFixed(2);

            statuses.forEach(status => {
                if (status === "Approved") {
                    countFunApproved++;
                };
                if (status === "Submitted") countFunSubmitted++;
                if (status === "Rejected") countFunRejected++;
            });

            const allApproved = statuses.length > 0 && statuses.every(status => status === "Approved");
            const anyRejected = statuses.some(status => status === "Rejected");
            const value = anyRejected ? "Rejected" : (allApproved ? "Approved" : "");
        });
        setCountLogTime(formattedTotalHours);
        setCountApproved(countFunApproved);
        setCountSubmitted(countFunSubmitted);
        setCountRejected(countFunRejected);
        setHoursApproved(ApprovedHours.toFixed(2))
        setHoursSubmitted(SubmittedHours.toFixed(2))
        setHoursRejected(RejectedHours.toFixed(2))
    }

    useEffect(() => {
        getCountOfTimeCardStatus()

    }, [timeCardsShowData, getCountOfTimeCardStatus, selectedOptionForSort, sortedDataForTimeCard,]);
    const getBorderColor = (RecordType) => {
        switch (RecordType) {
            case "Approved":
                return "rgb(42, 192, 28)";
            case "Rejected":
                return "red";
            case "Submitted":
                return "blue";
            default:
                return "#1011a";
        }
    };
    const getBackgroundColor = (RecordType) => {
        switch (RecordType) {
            case "Approved":
                return "#ebffeb";
            case "Rejected":
                return "#fee8e8";
            default:
                return "#fff";
        }
    };
    const SortedData = (type, data) => {
        let SortedNewData = [];


        if (type == 'Rejected') {
            SortedNewData = data.filter(data =>
                data.entries[0].timeCardEntries.some(entry => entry.status === 'Rejected')
            );
            setTimeCardsShowData(SortedNewData);
        }
        else if (type == 'Submitted') {
            SortedNewData = data.filter(data =>
                data.entries[0].timeCardEntries.some(entry => entry.status === 'Submitted')
            );
            setTimeCardsShowData(SortedNewData);
        }
        else if (type == 'Approved') {
            SortedNewData = data.filter(data =>
                data.entries[0].timeCardEntries.every(entry => entry.status === 'Approved')
            );
            setTimeCardsShowData(SortedNewData);
        }
        else {
            SortedNewData = [...data].sort((a, b) =>
                new Date(b.timeCardDate) - new Date(a.timeCardDate)
            );
            setTimeCardsShowData(SortedNewData);
        }

    };


    const handleChangeForShowData = (event) => {
        event.preventDefault();
        setSelectedOptionForSort(event.target.value);
        const val = event.target.value
        SortedData(val, sortedDataForTimeCard)
    };
    const newForMateDate = (dateString) => {
        const date = new Date(dateString);

        if (isNaN(date)) {

            return "";
        }

        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('en-GB', { month: 'short' });
        const year = date.getFullYear();
        const weekday = date.toLocaleString('en-GB', { weekday: 'short' });

        return `${day} ${month} ${year} (${weekday})`;
    }

    return (
        <div style={{ backgroundColor: 'white' }}>
            <HrmNavbar />
            <div className=''>
                {messageFromBackend &&
                    <div className="messageofHrm">
                        <div className={`${messageType}`}>
                            {messageFromBackend}
                        </div>
                    </div>
                }
            </div>
            <LoaderForHrm loading={loading} />
            <div style={{ display: "flex", justifyContent: 'right', margin: "0" }} className='hrmTimeCard_main_div'>
                <Link to="https://antrazal.my.salesforce.com/sfc/p/#5g00000LPmQC/a/J1000000JzmE/Slya_DPYYGKAMZp7N1MtIfbI2L5NG5OV7FSoxnL7c8E" target="_blank" className='anchorTagForTimeCard' rel="canonical">
                    <li className="" style={{ height: "auto", borderBottom: "1px solid blue", listStyle: 'none' }}>Time Card Policies</li>
                </Link>
            </div>
            <div className='hrmTimeCard_main_div' style={{ backgroundColor: 'white' }}>

                <div className='FlexClassCOntainer'>
                    <div className='totalTimeCardFlexDiv'>
                        <h1 className='timeCardDate'><DateCalender timeDate={timeDate} time={time} /></h1>
                        <p style={{ margin: '0', color: 'black' }}>Total Hours: {countLogTime}H</p>
                    </div>
                    <div className='showCountOfTimecard'>
                        <div><p>Approved ({countApproved} | {hoursApproved}H)</p></div>
                        <div><p>Submitted ({countSubmitted} | {hoursSubmitted}H)</p></div>
                        <div><p>Rejected ({countRejected} | {hoursRejected}H)</p></div>
                        <div>
                            <label htmlFor="" style={{ marginRight: "0.3rem" }}>Sorted By:</label>
                            <select
                                id="task-filter"
                                value={selectedOptionForSort}
                                onChange={handleChangeForShowData}
                                style={{ border: " 1px solid #ccc" }}
                            >
                                {/* <option value="All">All</option> */}
                                <option value="Date">Date</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Approved">Approved</option>
                                <option value="Submitted">Submitted</option>
                            </select>

                        </div>
                    </div>
                </div>
                {showTimeEnteries && showTimeEnteries == true ?
                    <>
                        <div className='hrmtimedetailCard_main_div'>
                            <div className='hrmtimedetailCard_div'>
                                <div className='timeCard-heading hideHeadingMobileView'>
                                    <div>Project</div>
                                    <div>Task Id</div>
                                    <div>Description</div>
                                    <div>Working Hours</div>
                                </div>

                                {timeCards.map((card, index) => (
                                    !timeCardValues || timeCardValues[index]?.isDeleted ? null : (
                                        <div className='timeCard' key={index}>
                                            <div className='inputGroup timeCardInputDiv'>
                                                <label className='timeCardLabel'>Select Project</label>
                                                <Select
                                                    options={allProjectOption}
                                                    value={timeCardValues[index]?.projAlId}
                                                    onChange={(value) => handleProjectChange(index, value)}
                                                    required
                                                    isDisabled={timeCardValues[index]?.status === "Approved"}
                                                    style={{ pointerEvents: timeCardValues[index]?.status === "Approved" ? "none" : "auto" }}
                                                />
                                            </div>
                                            <div className='inputGroup'>
                                                <label className='timeCardLabel'>Select ProjecT Task </label>
                                                <Select
                                                    options={getTaskOptions(timeCardValues[index]?.projAlId?.value)}
                                                    value={timeCardValues[index]?.taskId}
                                                    onChange={(value) => handleTaskIdChange(index, value)}
                                                    required
                                                    isDisabled={timeCardValues[index]?.status === "Approved"}

                                                    style={{ pointerEvents: timeCardValues[index]?.status === "Approved" ? "none" : "auto" }}
                                                />
                                            </div>
                                            <div className='timeCardTextarea'>
                                                <label className='timeCardLabel'>Enter Description</label>
                                                <textarea
                                                    name=""
                                                    id="hrmmyTextarea"
                                                    placeholder='Enter here'
                                                    onChange={(e) => handleTextareaChange(index, e.target.value, 'description')}
                                                    required
                                                    value={timeCardValues[index]?.description}
                                                    isDisabled={timeCardValues[index]?.status === "Approved"}
                                                    style={{ pointerEvents: timeCardValues[index]?.status === "Approved" ? "none" : "auto" }}
                                                ></textarea>
                                            </div>
                                            <div>
                                                <label className='timeCardLabel'>Working Hours</label>
                                                <input
                                                    value={timeCardValues[index]?.hours}
                                                    type="number"
                                                    placeholder=""
                                                    className="timeCardTimeinput"
                                                    min="0"
                                                    max="2"
                                                    onChange={(e) => handleTimeInputChange(index, e.target.value, 'hours')}
                                                    required
                                                    id='hrmmytimeInput'
                                                    isDisabled={timeCardValues[index]?.status === "Approved"}
                                                    style={{ pointerEvents: timeCardValues[index]?.status === "Approved" ? "none" : "auto" }}
                                                />
                                            </div>
                                            <div className='trashcard'>
                                                <svg
                                                    viewBox="0 0 1024 1024"
                                                    fill="#ccc"
                                                    isDisabled={timeCardValues[index]?.status === "Approved"}
                                                    version="1.1"
                                                    stroke="black"
                                                    className={`icon ${timeCardValues[index]?.status === "Approved" ? "disabled" : ""}`}
                                                    onClick={() => removeTimeCard(index, timeCardValues[index]?.Id, timeCardValues[index]?.isDeleted)}
                                                    style={{ pointerEvents: timeCardValues[index]?.status === "Approved" ? "none" : "auto", cursor: 'pointer' }}
                                                >
                                                    <path d="M32 241.6c-11.2 0-20-8.8-20-20s8.8-20 20-20l940 1.6c11.2 0 20 8.8 20 20s-8.8 20-20 20L32 241.6zM186.4 282.4c0-11.2 8.8-20 20-20s20 8.8 20 20v688.8l585.6-6.4V289.6c0-11.2 8.8-20 20-20s20 8.8 20 20v716.8l-666.4 7.2V282.4z" fill="black"></path>
                                                    <path d="M682.4 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM367.2 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM524.8 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM655.2 213.6v-48.8c0-17.6-14.4-32-32-32H418.4c-18.4 0-32 14.4-32 32.8V208h-40v-42.4c0-40 32.8-72.8 72.8-72.8H624c40 0 72.8 32.8 72.8 72.8v48.8h-41.6z" fill="black"></path>
                                                </svg>
                                                {index === timeCards.length - 1 && (
                                                    <svg fill="#00000" viewBox="0 0 32 32" version="1.1" onClick={addTimeCard} style={{ cursor: 'pointer', height: "1.5rem", width: "1.5rem" }} stroke="#00000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032zM23 15h-6v-6c0-0.552-0.448-1-1-1s-1 0.448-1 1v6h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1h6v6c0 0.552 0.448 1 1 1s1-0.448 1-1v-6h6c0.552 0 1-0.448 1-1s-0.448-1-1-1z"></path> </g></svg>
                                                )}
                                            </div>
                                        </div>
                                    )
                                ))}


                                <div className='nrmTimecardButtonSubmit' style={{ gap: "1rem", color: "black" }}>
                                    <div className="totalHoursDisplay">
                                        <b> Total Hours: {timeCardValues
                                            .filter(card => !card.isDeleted)
                                            .reduce((total, card) => total + (parseFloat(card.hours) || 0), 0).toFixed(2)}</b>
                                    </div>
                                    {
                                        buttonloading ? (
                                            <div style={{width: "5rem", marginBottom:"0.5rem"}}><LoaderForHrm loading={buttonloading} /></div>
                                        ) : (
                                            !editMode ? (
                                                <button onClick={handleSaveTimeCard}>Submit</button>
                                            ) : (
                                                <button onClick={handelUpdateCard}>Update</button>
                                            )
                                        )
                                    }




                                </div>
                            </div>
                        </div>
                    </>
                    : null}
            </div>
            <div className='adminPanelMrmTable' style={{ width: "100%" }}>
                <div className='hrmTimeCard_main_div'>
                    <div>
                        <h1 className='TimeCardHeading '>Time card Data </h1>
                    </div>
                    <div>

                        <div>
                            {Array.isArray(timeCardsShowData) && timeCardsShowData.map((card, index) => {
                                let totalHours = 0;
                                const statuses = card.entries.flatMap(entry =>
                                    entry.timeCardEntries.map(timeCardEntry => timeCardEntry.status)
                                );

                                const allApproved = statuses.length > 0 && statuses.every(status => status === "Approved");
                                const anyRejected = statuses.some(status => status === "Rejected");
                                const value = anyRejected ? "Rejected" : (allApproved ? "Approved" : "");
                                card.entries.forEach(entry => {
                                    entry.timeCardEntries.forEach(task => {
                                        totalHours += parseFloat(task.hours || 0);
                                    });
                                });
                                return (
                                    <div key={index} style={{ margin: "0.3rem 0" }}>
                                        <div
                                            className={openIndex === index ? 'timeCard-show-div selectedTimeCard' : 'timeCard-show-div'}
                                            onClick={() => toggleDiv(index)}
                                            style={{
                                                border: `${value === "" ? '2px' : "2px"} solid ${getBorderColor(value)}`,
                                                backgroundColor: `${getBackgroundColor(value)}`
                                            }}
                                        >
                                            <p className='DateParaTimeCard'>{newForMateDate(card.timeCardDate)} {allApproved}</p>
                                            <div>
                                                <div style={{ color: 'black', display: 'flex', alignItems: 'center', marginRight: '1rem', fontSize: '0.9rem' }}>Total  Working Hours:&nbsp; <b>{totalHours.toFixed(2)}</b>
                                                </div>
                                                <div>
                                                    <svg
                                                        fill="#1011a2"
                                                        viewBox="-6.5 0 32 32"
                                                        version="1.1"
                                                        className={openIndex === index ? '' : 'rotate-icon'}
                                                    >
                                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <title>dropdown</title>
                                                            <path d="M18.813 11.406l-7.906 9.906c-0.75 0.906-1.906 0.906-2.625 0l-7.906-9.906c-0.75-0.938-0.375-1.656 0.781-1.656h16.875c1.188 0 1.531 0.719 0.781 1.656z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {openIndex === index && (
                                            <div className="timcard-hidden-div" style={{
                                                border: `1px solid ${getBorderColor(value)}`
                                            }}>
                                                <div style={{ display: "flex", justifyContent: 'end', gap: '1rem' }}>
                                                    <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>Total  Working Hours:&nbsp; <b>{parseFloat(totalHours.toFixed(2))}</b>
                                                    </div>
                                                    <div className="timecardHeading" onClick={() => handelEditBtn(index, card.timeCardDate, card.Id)}><button className="approvedBtn">Update</button></div>

                                                </div>
                                                <div className='tablemobileView'>

                                                    <div className='outerDiv-For-TimeCard ' style={{ backgroundColor: `${getBackgroundColor(value ? value : '')}` }}>
                                                        <div className="headerRow">
                                                            <div className="headerRowChild timeCardprojectNameDiv">Project Name</div>
                                                            <div className="headerRowChild timeCardTaskDiv">Task Id</div>
                                                            <div className="headerRowChild timeCardWorkingHoursDiv">Working Hours</div>
                                                            <div className="headerRowChild timeCardDiscription">Description</div>
                                                        </div>
                                                        {card.entries.map((entry, entryIndex) => {

                                                            return (
                                                                <div key={entryIndex}>
                                                                    {entry.timeCardEntries.map((task, taskIndex) => (
                                                                        <div className="outerDiv-For-TimeCard-content" style={{ border: `1px solid ${getBorderColor(task.status ? task.status : '')}`, backgroundColor: `${getBackgroundColor(task.status ? task.status : '')}` }}>
                                                                            <div className="headerCell timeCardprojectNameDiv">{task.projAlId ? task.projAlId : ''}</div>
                                                                            <div className="headerCell timeCardTaskDiv" style={{ borderLeft: `1px solid ${getBorderColor(task.status ? task.status : '')}` }}>{task.peId ? task.peId : ''}</div>
                                                                            <div className="headerCell timeCardWorkingHoursDiv" style={{ borderLeft: `1px solid ${getBorderColor(task.status ? task.status : '')}` }}>{task.hours ? task.hours : ''}</div>
                                                                            <div className="headerCell timeCardDiscription" style={{ borderLeft: `1px solid ${getBorderColor(task.status ? task.status : '')}` }}><div style={{ display: "flex", gap: "0.5rem", justifyContent: "space-between" }}>
                                                                                <div>{task.description ? task.description : ''}</div>
                                                                                {
                                                                                    task.status && task.status === "Rejected" ? (<div style={{ padding: "", justifyContent: "center", position: "relative" }} >
                                                                                        <svg
                                                                                            viewBox="0 0 24 24"
                                                                                            fill="none"
                                                                                            transform="matrix(-1, 0, 0, 1, 0, 0)"
                                                                                            style={{ height: "1.5rem", width: "1.5rem" }}
                                                                                            className='assignToLogoStyleNew'
                                                                                        >
                                                                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                                            <g
                                                                                                id="SVGRepo_tracerCarrier"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                            ></g>
                                                                                            <g id="SVGRepo_iconCarrier">
                                                                                                <path
                                                                                                    d="M8 12H8.01M12 12H12.01M16 12H16.01M21.0039 12C21.0039 16.9706 16.9745 21 12.0039 21C9.9675 21 3.00463 21 3.00463 21C3.00463 21 4.56382 17.2561 3.93982 16.0008C3.34076 14.7956 3.00391 13.4372 3.00391 12C3.00391 7.02944 7.03334 3 12.0039 3C16.9745 3 21.0039 7.02944 21.0039 12Z"
                                                                                                    stroke="rgb(16, 17, 162)"
                                                                                                    strokeWidth="2"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                ></path>
                                                                                            </g>
                                                                                        </svg>
                                                                                        <div className='messageForTimeCard'>{task.comment ? task.comment : ''}</div>
                                                                                    </div>) : (<></>)

                                                                                }
                                                                            </div></div>
                                                                        </div>
                                                                    ))}
                                                                </div>)
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}



