import React, { useState, useEffect } from "react";
import "../Components/css/blogD.css";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import SetMeeting from "../Components/SetMeeting";
import { TabTitle } from "./TabTitle";
/*
*********************************************************************************************************
*  @Parent Component           :    Blog
*  @React Component Name       :    BlogD
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Blog detail page
********************************************************************************************************
*/
function BlogD() {
	// const [searchParams] = useSearchParams();
	// const name = searchParams.get("viewmore");
	const pathname = window.location.pathname; 
const name = pathname.replace(/^\/blog\//, ''); // Remove "/services" from the beginning
// console.log(name);
	const [posts, setPosts] = useState([]);
	TabTitle(`${name || "Blogs"} | Salesforce Development Services | Antrazal','Antrazal’s salesforce consulting company delivers exceptional Salesforce services worldwide. With global expertise and a team of experts.`)

	/*
*********************************************************************************************************
*   @React Component Name       :       Blog
*   @Method                     :       useEffect
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       initialy call the api and render the data  
*********************************************************************************************************
*/

	useEffect(() => {
		fetchPosts();
	}, []);

	/*
*********************************************************************************************************
*   @React Component Name       :       BlogD
*   @Method                     :       useEffect
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       fetch the data from the server  
*********************************************************************************************************
*/

	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/blog`)
			.then((res) => res.json())
			.then((resData) => {
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);
			});
	};

	return (
		(
			posts.map((item) => {
				return (item.title.toLowerCase().replace(/\s+/g, '-') === name) ? (
					<>
						<section className="" style={{ padding: "8rem 0 0rem 0px" }} key={item.id}>
							<Container>
								{item.authorImage !== null && item.authorImage !== "" && item.authorName !== "" && (
									<section>
										<h1 className="blogDatial-heading">{item.title}</h1>
										<div className="authors">
											<div className="author-pictures">
												<div
													className="image"
													style={{
														backgroundImage: `url(${item.authorImage})`
													}}
													alt=""
												></div>
											</div>
											<div className="info">
												<div className="author-names">
													<p>{item.authorName}</p>
												</div>
												<div className="post-date">{item.created_date}</div>
											</div>
										</div>
									</section>
								)}
							</Container>
						</section >
						<section style={{ margin: "0rem 0", padding: "1rem " }}>
							<Container dangerouslySetInnerHTML={{ __html: item.content }} style={{ padding: "1rem " }} className="blog_html_css">
							</Container>
						</section>
						<SetMeeting />
					</>
				) : null
			})
		)
	);
}

export default BlogD;







